var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "offer-report" }, [
    _c("div", { staticClass: "offer-report-content" }, [
      !_vm.shareLogId
        ? _c("div", { staticClass: "offer-report-title" }, [
            _c("div", { staticClass: "table-title" }, [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.offerTitleMap[_vm.type]) +
                  "\n                "
              ),
              _c("span", [_vm._v(_vm._s(_vm.totalCount))]),
            ]),
            _c(
              "div",
              { staticClass: "offer-report-option" },
              [
                _c("div", { staticClass: "sub-title" }, [
                  _vm.isInternalControl && !_vm.isAgreementJob
                    ? _c(
                        "div",
                        { staticClass: "employees" },
                        [
                          _c(
                            "el-popover",
                            {
                              attrs: {
                                "popper-class":
                                  "team-member-popover static-team-member-popover",
                                placement: "bottom-end",
                                width: "700",
                                trigger: "click",
                                disabled: !_vm.isInternalControl,
                              },
                              model: {
                                value: _vm.teamMemberPopoverVisible,
                                callback: function ($$v) {
                                  _vm.teamMemberPopoverVisible = $$v
                                },
                                expression: "teamMemberPopoverVisible",
                              },
                            },
                            [
                              _c("team-member-select", {
                                ref: "teamSelect",
                                attrs: {
                                  isAllMembers: true,
                                  isShowDimission: false,
                                  date: _vm.dateRange,
                                  isHistoryData: true,
                                  visible: _vm.teamMemberPopoverVisible,
                                  selected: _vm.recommendOwnerIds,
                                },
                                on: { "item-select-cb": _vm.employeeSelect },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "employee-select-text",
                                  attrs: { slot: "reference" },
                                  slot: "reference",
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "icon-font icon-user-select doll visible",
                                  }),
                                  _c("span", { staticClass: "text" }, [
                                    _vm._v(_vm._s(_vm.selectedMembersText)),
                                  ]),
                                  !_vm.teamMemberPopoverVisible
                                    ? _c("i", {
                                        staticClass: "el-icon-caret-bottom",
                                      })
                                    : _c("i", {
                                        staticClass: "el-icon-caret-top",
                                      }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  !_vm.shareLogId
                    ? _c(
                        "div",
                        {
                          staticClass: "date-wrap triangle-drop",
                          class: _vm.isFocusDate
                            ? "triangle-drop-up"
                            : "triangle-drop-down",
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "daterange",
                              "start-placeholder": "开始日期",
                              "range-separator": "-",
                              "end-placeholder": "结束日期",
                              size: "small",
                              editable: false,
                              align: "center",
                              format: "yyyyMMdd",
                              "value-format": "yyyy-MM-dd",
                              clearable: false,
                              "picker-options": _vm.expireTimeOption,
                              "popper-class": "performance-data-range",
                            },
                            on: {
                              change: _vm.handleDate,
                              focus: _vm.handleFocusDate,
                              blur: _vm.handleBlurDate,
                            },
                            model: {
                              value: _vm.dateRange,
                              callback: function ($$v) {
                                _vm.dateRange = $$v
                              },
                              expression: "dateRange",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  this.searchType
                    ? _c(
                        "div",
                        { staticClass: "client-select-wrap" },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "select-client triangle-drop",
                              class: _vm.clientVisible
                                ? "triangle-drop-up"
                                : "triangle-drop-down",
                              attrs: { placeholder: "请选择客户" },
                              on: {
                                change: _vm.getData,
                                focus: _vm.clientSelectFocus,
                                blur: _vm.clientSelectBlur,
                              },
                              model: {
                                value: _vm.clientValue,
                                callback: function ($$v) {
                                  _vm.clientValue = $$v
                                },
                                expression: "clientValue",
                              },
                            },
                            _vm._l(_vm.clientOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                staticClass: "client-select-item",
                                attrs: {
                                  title: item.name,
                                  label: item.name,
                                  value: item.id,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "el-button",
                  {
                    staticClass: "offer-report-title-btn export",
                    attrs: { type: "primary", loading: _vm.exportLoading },
                    on: { click: _vm.handleExport },
                  },
                  [_vm._v("导出明细")]
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.shareLogId
        ? _c("div", { staticClass: "offer-report-title" }, [
            _vm._v("来自" + _vm._s(_vm.shareFrom) + "分享的Tracking List"),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "offer-report-table" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "relateOfferTable",
              attrs: {
                data: _vm.tableData,
                border: "",
                "tooltip-effect": "light",
              },
              on: { "cell-mouse-enter": _vm.handleCellMouseEnter },
            },
            [
              !_vm.isCaculateOpen && !_vm.shareLogId
                ? _vm._l(_vm.offerTableTitle, function (item) {
                    return _c("el-table-column", {
                      key: item.field,
                      class: item.class,
                      attrs: {
                        prop: item.field,
                        align: "left",
                        stripe: "",
                        "show-overflow-tooltip": true,
                        resizable: false,
                        label: item.label,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                item.field == "title"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `/#/Job/${scope.row.jobId}`,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.title))]
                                    )
                                  : _vm._e(),
                                item.field == "locations"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(scope.row.locations?.join())
                                      ),
                                    ])
                                  : _vm._e(),
                                item.field == "realName"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `/#/candidateDetail/${scope.row.candidateId}`,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.realName))]
                                    )
                                  : _vm._e(),
                                item.field != "title" &&
                                item.field != "locations" &&
                                item.field != "realName"
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.columnFormatter(
                                            item.field,
                                            scope.row[item.field]
                                          )
                                        )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  })
                : _vm._e(),
              _vm.isCaculateOpen && !_vm.shareLogId
                ? [
                    _vm._l(_vm.allOffersTableTitle, function (item) {
                      return _c(
                        "el-table-column",
                        {
                          key: item.field,
                          class: item.class,
                          attrs: {
                            prop: item.field,
                            align: "left",
                            "min-width": item.width,
                            fixed: item.field == "recommendName",
                            "show-overflow-tooltip": true,
                            resizable: false,
                            label: item.label,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    item.field == "title"
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: `/#/Job/${scope.row.jobId}`,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.title))]
                                        )
                                      : _vm._e(),
                                    item.field == "locations"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(scope.row.locations.join())
                                          ),
                                        ])
                                      : _vm._e(),
                                    item.field == "realName"
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: `/#/candidateDetail/${scope.row.candidateId}/recommendation/${scope.row.recommendId}`,
                                              target: "_blank",
                                            },
                                          },
                                          [_vm._v(_vm._s(scope.row.realName))]
                                        )
                                      : _vm._e(),
                                    item.field == "customerDomain"
                                      ? _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              scope.row.customerDomain || "-"
                                            ),
                                          },
                                        })
                                      : _vm._e(),
                                    item.field != "title" &&
                                    item.field != "locations" &&
                                    item.field != "realName" &&
                                    item.field != "customerDomain"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.columnFormatter(
                                                item.field,
                                                scope.row[item.field]
                                              )
                                            )
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          item.field == "performance"
                            ? _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  { staticClass: "table-header-tip-cell" },
                                  [
                                    _vm._v(
                                      "\n                                业绩\n                                "
                                    ),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top-start",
                                          width: "240",
                                          trigger: "hover",
                                          "visible-arrow": false,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    选中成员的分成值总和：如果是个人，则这里是个人业绩；如果选中的是整个团队成员，则这里是团队业绩。\n                                    "
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "icon-question",
                                            attrs: { slot: "reference" },
                                            slot: "reference",
                                          },
                                          [
                                            _c("font-icon", {
                                              staticClass:
                                                "table-header-tip-icon question-hover",
                                              attrs: {
                                                href: "#icon-talent_ql",
                                              },
                                            }),
                                            _c("font-icon", {
                                              staticClass:
                                                "table-header-tip-icon question-no-hover",
                                              attrs: {
                                                href: "#icon-talent_qd",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    _vm._l(_vm.extraTableTitle, function (item) {
                      return _c("el-table-column", {
                        key: item.field,
                        class: item.class,
                        attrs: {
                          align: "left",
                          "min-width": item.width,
                          "show-overflow-tooltip": true,
                          label: item.label,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function ({ column }) {
                                return [
                                  _c(
                                    "span",
                                    { staticClass: "table-header-tip-cell" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(column.label) +
                                          "\n                                "
                                      ),
                                      item.field == "TS"
                                        ? _c(
                                            "el-popover",
                                            {
                                              attrs: {
                                                placement: "top-start",
                                                width: "240",
                                                trigger: "hover",
                                                "visible-arrow": false,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    在完成开票前，TS部分分成比例可能因简历归属变化而发生变化。\n                                    "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "icon-question",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                },
                                                [
                                                  _c("font-icon", {
                                                    staticClass:
                                                      "table-header-tip-icon question-hover",
                                                    attrs: {
                                                      href: "#icon-talent_ql",
                                                    },
                                                  }),
                                                  _c("font-icon", {
                                                    staticClass:
                                                      "table-header-tip-icon question-no-hover",
                                                    attrs: {
                                                      href: "#icon-talent_qd",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _vm._l(
                                    scope.row.offerItems,
                                    function (offerItem, index) {
                                      return [
                                        offerItem.name == item.field
                                          ? [
                                              _c(
                                                "span",
                                                {
                                                  key: offerItem.name + index,
                                                  staticClass:
                                                    "cell-share-data",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        offerItem.userName
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm._f(
                                                          "numPointFilter"
                                                        )(offerItem.percent)
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        _vm._f(
                                                          "numPointFilter"
                                                        )(offerItem.charge)
                                                      ) +
                                                      "\n                                        "
                                                  ),
                                                  _c("br"),
                                                ]
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                  item.field == "a2AOfferItems"
                                    ? [
                                        scope.row.a2AOfferItems &&
                                        scope.row.a2AOfferItems.length > 0
                                          ? [
                                              _c(
                                                "el-popover",
                                                {
                                                  attrs: {
                                                    placement: "top-start",
                                                    width: "200",
                                                    trigger: "hover",
                                                  },
                                                },
                                                [
                                                  scope.row.a2AOfferItems
                                                    .length > 1
                                                    ? _c("i", {
                                                        staticClass:
                                                          "el-icon-chat-dot-round",
                                                        attrs: {
                                                          slot: "reference",
                                                        },
                                                        slot: "reference",
                                                      })
                                                    : _vm._e(),
                                                  _vm._l(
                                                    scope.row.a2AOfferItems,
                                                    function (a2aItem, aIndex) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: "a2a_" + aIndex,
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                            " +
                                                              _vm._s(
                                                                a2aItem?.userName
                                                              ) +
                                                              "/" +
                                                              _vm._s(
                                                                a2aItem?.charge
                                                              ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              ),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    scope.row?.a2AOfferItems[0]
                                                      ?.userName
                                                  ) +
                                                  "/" +
                                                  _vm._s(
                                                    scope.row?.a2AOfferItems[0]
                                                      ?.charge
                                                  ) +
                                                  "\n                                    \n                                "
                                              ),
                                            ]
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    }),
                  ]
                : _vm._e(),
              _vm.shareLogId
                ? _vm._l(_vm.trackListShareTitle, function (item) {
                    return _c("el-table-column", {
                      key: item.field,
                      class: item.class,
                      attrs: {
                        prop: item.field,
                        align: "left",
                        stripe: "",
                        "show-overflow-tooltip": true,
                        resizable: false,
                        label: item.label,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                item.field == "inquiryLogCount"
                                  ? _c(
                                      "span",
                                      [
                                        scope.row.resumeListItem
                                          .inquiryLogsCount > 0
                                          ? _c("inquiry-log", {
                                              attrs: { resume: scope.row },
                                              on: {
                                                "update-item":
                                                  _vm.handleUpdateItem,
                                              },
                                            })
                                          : [
                                              _c("font-icon", {
                                                staticClass:
                                                  "table-body-icon-big icon-inquiry",
                                                attrs: {
                                                  href: "#icon-talent_inquiry",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    scope.row.inquiryLogCount
                                                  ) +
                                                  " \n                                "
                                              ),
                                            ],
                                      ],
                                      2
                                    )
                                  : item.field == "realName"
                                  ? _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: `/#/candidateDetail/${scope.row.resumeId}`,
                                          target: "_blank",
                                        },
                                      },
                                      [_vm._v(_vm._s(scope.row.realName))]
                                    )
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.columnFormatter(
                                            item.field,
                                            scope.row[item.field]
                                          )
                                        )
                                      ),
                                    ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    })
                  })
                : _vm._e(),
              !_vm.shareLogId && _vm.type != "offerList"
                ? _c("el-table-column", {
                    key: "candidateStatus",
                    attrs: {
                      prop: "candidateStatus",
                      align: "left",
                      stripe: "",
                      "show-overflow-tooltip": true,
                      resizable: false,
                      "min-width": "176",
                      label: "候选人状态",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(scope.row.candidateStatus) +
                                  "\n                        "
                              ),
                              scope.row.hasUpdateLog && _vm.isAdministrator
                                ? [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom-end",
                                          width: "480",
                                          "popper-class":
                                            "offer-edit-log-popper",
                                          "visible-arrow": false,
                                          trigger: "hover",
                                        },
                                        on: {
                                          show: function ($event) {
                                            return _vm.getEditLogList(
                                              scope.row.recommendId
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "loading",
                                                rawName: "v-loading",
                                                value: _vm.offerLogLoading,
                                                expression: "offerLogLoading",
                                              },
                                            ],
                                            staticClass: "offer-edit-log-list",
                                          },
                                          _vm._l(
                                            _vm.editOfferLogList,
                                            function (log, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass:
                                                    "offer-edit-log-item",
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "offer-info-time",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("dataFilter")(
                                                            log.updateTime
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "offer-log-title",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "offer-log-updator",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              log.updaterName
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _vm._v("编辑Offer"),
                                                    ]
                                                  ),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "offer-log-title",
                                                    },
                                                    [_vm._v("编辑前")]
                                                  ),
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.transformOfferLog(
                                                          log.originModel
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "offer-log-title",
                                                    },
                                                    [_vm._v("编辑后")]
                                                  ),
                                                  _c("div", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.transformOfferLog(
                                                          log.updateModel
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                        _c("i", {
                                          staticClass: "el-icon-document",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ]
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1550083247
                    ),
                  })
                : _vm._e(),
              _vm.isCaculateOpen && !_vm.shareLogId
                ? _c("el-table-column", {
                    key: "candidateSourceChannel",
                    staticClass: "cell-recommend-name",
                    attrs: {
                      align: "left",
                      "min-width": "180",
                      prop: "candidateSourceChannel",
                      "show-overflow-tooltip": true,
                      resizable: false,
                      label: "简历来源渠道",
                    },
                  })
                : _vm._e(),
              _vm.isCFUser && !_vm.shareLogId
                ? _c("el-table-column", {
                    key: "businessClassificationName",
                    staticClass: "cell-recommend-name",
                    attrs: {
                      align: "left",
                      "min-width": "180",
                      prop: "businessClassificationName",
                      "show-overflow-tooltip": true,
                      resizable: false,
                      label: "业务分类",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    scope.row.businessClassificationName || "-"
                                  ) +
                                  "\n                    "
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      716034983
                    ),
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "work-table-footer" },
            [
              _c(
                "el-pagination",
                {
                  staticClass: "el-pagination-workTable",
                  attrs: {
                    "current-page": _vm.page,
                    "page-sizes": [15, 20, 50],
                    "page-size": _vm.pageSize,
                    layout: "total, sizes, prev, pager, next, slot",
                    total: _vm.totalCount,
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                },
                [
                  _c("span", { staticClass: "pagination-text" }, [
                    _c(
                      "span",
                      [
                        _vm._v("前往"),
                        _c("el-input", {
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.handlePagerJump.apply(null, arguments)
                            },
                          },
                          model: {
                            value: _vm.pagerJump,
                            callback: function ($$v) {
                              _vm.pagerJump = $$v
                            },
                            expression: "pagerJump",
                          },
                        }),
                        _vm._v("页"),
                      ],
                      1
                    ),
                    _c("span", { on: { click: _vm.handlePagerJump } }, [
                      _vm._v("跳转"),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }