export default [
    {
        field: 'realName',
        label: '候选人姓名',
        class: 'cell-recommend-name',
        width: '74'
    },
    {
        field: 'title',
        label: '职位',
        class: 'cell-recommend-name',
        width: '82'
    },
    {
        field: 'company',
        label: '公司',
        class: 'cell-recommend-name',
        width: '96'
    },
    {
        field: 'city',
        label: '城市',
        class: 'cell-recommend-name',
        width: '96'
    },
    {
        field: 'degree',
        label: '学历',
        class: 'cell-recommend-name',
        width: '96'
    },
    {
        field: 'school',
        label: '学校',
        class: 'cell-recommend-name',
        width: '82'
    },
    {
        field: 'yearOfExperience',
        label: '工作年限',
        class: 'cell-recommend-name',
        width: '104'
    },
    {
        field: 'userRecommendationCount',
        label: '成员推荐次数',
        class: 'cell-recommend-name',
        width: '104'
    },
    {
        field: 'inquiryLogCount',
        label: '寻访记录条数',
        class: 'cell-recommend-name',
        width: '104'
    },
];