<template>
    <div class="offer-report">
        <div class="offer-report-content">
            <div class="offer-report-title" v-if="!shareLogId">
                <div class="table-title">
                    <!-- {{ isRelateOffer ? '相关Offer数：' : 'Offer数：'}} -->
                    {{offerTitleMap[type]}}
                    <span>{{ totalCount }}</span>
                </div>

                <div class="offer-report-option">
                    <div class="sub-title">
                        <div class="employees" v-if="isInternalControl && !isAgreementJob">
                            <el-popover
                                popper-class="team-member-popover static-team-member-popover"
                                placement="bottom-end"
                                width="700"
                                trigger="click"
                                v-model="teamMemberPopoverVisible"
                                :disabled="!isInternalControl"
                            >
                                <team-member-select
                                    ref="teamSelect"
                                    :isAllMembers="true"
                                    :isShowDimission="false"
                                    :date="dateRange"
                                    :isHistoryData="true"
                                    :visible="teamMemberPopoverVisible"
                                    :selected="recommendOwnerIds"
                                    @item-select-cb="employeeSelect"
                                ></team-member-select>
                                <div slot="reference" class="employee-select-text">
                                    <i class="icon-font icon-user-select doll visible"></i>
                                    <span class="text">{{selectedMembersText}}</span>
                                    <i v-if="!teamMemberPopoverVisible" class="el-icon-caret-bottom"></i>
                                    <i v-else class="el-icon-caret-top"></i>
                                </div>
                            </el-popover>
                        </div>
                        
                        <!-- 时间段选择 -->
                        <div 
                            class="date-wrap triangle-drop" 
                            v-if="!shareLogId"
                            :class="isFocusDate?'triangle-drop-up':'triangle-drop-down'">
                            <el-date-picker
                                v-model="dateRange"
                                type="daterange"
                                start-placeholder="开始日期"
                                range-separator="-"
                                end-placeholder="结束日期"
                                size="small"
                                :editable="false"
                                align="center"
                                format="yyyyMMdd"
                                value-format="yyyy-MM-dd"
                                @change="handleDate"
                                @focus="handleFocusDate"
                                @blur="handleBlurDate"
                                :clearable="false"
                                :picker-options="expireTimeOption"
                                popper-class="performance-data-range">
                            </el-date-picker>
                        </div>
                        <!-- 选择客户 -->
                        <div v-if="this.searchType" class="client-select-wrap">
                            <el-select 
                                class="select-client triangle-drop" 
                                :class="clientVisible?'triangle-drop-up':'triangle-drop-down'"
                                v-model="clientValue" 
                                placeholder="请选择客户"
                                @change="getData"
                                @focus="clientSelectFocus"
                                @blur="clientSelectBlur">
                                <el-option
                                    class="client-select-item"
                                    v-for="item in clientOptions"
                                    :title="item.name"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                    <el-button class="offer-report-title-btn export" type="primary" :loading="exportLoading" @click="handleExport">导出明细</el-button>
                </div>
            </div>

            <div class="offer-report-title" v-if="shareLogId">来自{{shareFrom}}分享的Tracking List</div>
                
            <div class="offer-report-table">
                <el-table
                    v-loading="loading"
                    ref="relateOfferTable"
                    :data="tableData"
                    border
                    @cell-mouse-enter="handleCellMouseEnter"
                    tooltip-effect="light">
                    <template v-if="!isCaculateOpen && !shareLogId">
                        <el-table-column
                            v-for="item in offerTableTitle"
                            :key="item.field"
                            :prop="item.field"
                            align="left"
                            stripe
                            :class="item.class"
                            :show-overflow-tooltip="true"
                            :resizable="false"
                            :label="item.label">
                            <template slot-scope="scope">
                                <!-- <a v-if="item.field == 'title'" :href="`/Headhunting/MyCompany.html#/Job/${scope.row.jobId}`" target="_blank">{{ scope.row.title }}</a> -->
                                <a v-if="item.field == 'title'" :href="`/#/Job/${scope.row.jobId}`" target="_blank">{{ scope.row.title }}</a>
                                <span v-if="item.field == 'locations'">{{ scope.row.locations?.join() }}</span>
                                <!-- <a v-if="item.field == 'realName'" :href="`/Headhunting/MyCompany.html#/candidateDetail/${scope.row.candidateId}`" target="_blank">{{ scope.row.realName }}</a> -->
                                <a v-if="item.field == 'realName'" :href="`/#/candidateDetail/${scope.row.candidateId}`" target="_blank">{{ scope.row.realName }}</a>
                                <span v-if="item.field != 'title' && item.field != 'locations' && item.field != 'realName'">{{ columnFormatter(item.field, scope.row[item.field]) }}</span>
                            </template>
                        </el-table-column>
                    </template>

                    <template v-if="isCaculateOpen && !shareLogId">
                        <el-table-column
                            v-for="item in allOffersTableTitle"
                            :key="item.field"
                            :prop="item.field"
                            align="left"
                            :class="item.class"
                            :min-width="item.width"
                            :fixed="item.field == 'recommendName'"
                            :show-overflow-tooltip="true"
                            :resizable="false"
                            :label="item.label">
                            <template slot="header" v-if="item.field == 'performance'">
                                <span class="table-header-tip-cell">
                                    业绩
                                    <el-popover
                                        placement="top-start"
                                        width="240"
                                        trigger="hover"
                                        :visible-arrow="false"
                                    >
                                        选中成员的分成值总和：如果是个人，则这里是个人业绩；如果选中的是整个团队成员，则这里是团队业绩。
                                        <span slot="reference" class="icon-question">
                                            <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                            <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                                        </span>
                                    </el-popover>
                                </span>
                            </template>
                            <template slot-scope="scope">
                                <!-- <a v-if="item.field == 'title'" :href="`/Headhunting/MyCompany.html#/Job/${scope.row.jobId}`" target="_blank">{{ scope.row.title }}</a> -->
                                <a v-if="item.field == 'title'" :href="`/#/Job/${scope.row.jobId}`" target="_blank">{{ scope.row.title }}</a>
                                <span v-if="item.field == 'locations'">{{ scope.row.locations.join() }}</span>
                                <!-- <a v-if="item.field == 'realName'" :href="`/Headhunting/MyCompany.html#/candidateDetail/${scope.row.candidateId}/recommendation/${scope.row.recommendId}`" target="_blank">{{ scope.row.realName }}</a> -->

                                <!-- <template v-if="item.field == 'realName'">
                                    <a v-if="scope.row.jobSource == 'A2A职位'" :href="`/#/recommendation/${scope.row.recommendId}/recommendationList`" target="_blank">{{ scope.row.realName }}</a>
                                    <a v-else :href="`/#/candidateDetail/${scope.row.candidateId}/recommendation/${scope.row.recommendId}`" target="_blank">{{ scope.row.realName }}</a>
                                </template> -->
                                <a v-if="item.field == 'realName'" :href="`/#/candidateDetail/${scope.row.candidateId}/recommendation/${scope.row.recommendId}`" target="_blank">{{ scope.row.realName }}</a>
                                <!-- #/recommendation/'+recommendationid + '/recommendationList'; -->
                                <!-- jobSource:"A2A职位" -->
                                <span 
                                    v-if="item.field == 'customerDomain'"
                                    v-text="scope.row.customerDomain || '-'"
                                    ></span>
                                <span 
                                    v-if="item.field != 'title' && item.field != 'locations' && item.field != 'realName' && item.field != 'customerDomain'"
                                    >{{ columnFormatter(item.field, scope.row[item.field]) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            v-for="item in extraTableTitle"
                            :key="item.field"
                            align="left"
                            :class="item.class"
                            :min-width="item.width"
                            :show-overflow-tooltip="true"
                            :label="item.label">
                            <template slot="header" slot-scope="{column}" >
                                <span class="table-header-tip-cell">
                                    {{column.label}}
                                    <el-popover
                                        v-if="item.field == 'TS'"
                                        placement="top-start"
                                        width="240"
                                        trigger="hover"
                                        :visible-arrow="false"
                                    >
                                        在完成开票前，TS部分分成比例可能因简历归属变化而发生变化。
                                        <span slot="reference" class="icon-question">
                                            <font-icon class="table-header-tip-icon question-hover" href="#icon-talent_ql"></font-icon>
                                            <font-icon class="table-header-tip-icon question-no-hover" href="#icon-talent_qd"></font-icon>
                                        </span>
                                    </el-popover>
                                </span>
                            </template>
                            
                            <template slot-scope="scope">
                                <template v-for="(offerItem, index) in scope.row.offerItems">
                                    <template v-if="offerItem.name == item.field">
                                        <span class="cell-share-data" :key="offerItem.name + index">
                                            {{offerItem.userName}}/{{offerItem.percent | numPointFilter}}/{{offerItem.charge | numPointFilter}}
                                            <br>
                                        </span>
                                    </template>
                                </template>
                                <template v-if="item.field == 'a2AOfferItems'">
                                    <template v-if="scope.row.a2AOfferItems && scope.row.a2AOfferItems.length >0">
                                        
                                        <el-popover
                                            placement="top-start"
                                            width="200"
                                            trigger="hover"
                                            >
                                            <i slot="reference" 
                                                v-if="scope.row.a2AOfferItems.length>1"
                                                class="el-icon-chat-dot-round"></i>
                                            <div 
                                                v-for="(a2aItem, aIndex) in scope.row.a2AOfferItems"
                                                :key="'a2a_' + aIndex"
                                                >
                                                {{a2aItem?.userName}}/{{a2aItem?.charge}}
                                            </div>
                                        </el-popover>
                                        {{scope.row?.a2AOfferItems[0]?.userName}}/{{scope.row?.a2AOfferItems[0]?.charge}}
                                        
                                    </template>
                                </template>
                            </template>
                        </el-table-column>
                    </template>

                    <template v-if="shareLogId">
                        <el-table-column
                            v-for="item in trackListShareTitle"
                            :key="item.field"
                            :prop="item.field"
                            align="left"
                            stripe
                            :class="item.class"
                            :show-overflow-tooltip="true"
                            :resizable="false"
                            :label="item.label">
                            <template slot-scope="scope">
                                <span v-if="item.field == 'inquiryLogCount'">
                                    <inquiry-log
                                        :resume="scope.row"
                                        v-if="scope.row.resumeListItem.inquiryLogsCount>0"
                                        @update-item="handleUpdateItem"
                                    ></inquiry-log>
                                    <template v-else>
                                        <font-icon 
                                            class="table-body-icon-big icon-inquiry" href="#icon-talent_inquiry">
                                        </font-icon>
                                        {{scope.row.inquiryLogCount}} 
                                    </template>
                                </span>

                                <!-- <a v-else-if="item.field == 'realName'" :href="`/Headhunting/MyCompany.html#/candidateDetail/${scope.row.resumeId}`" target="_blank">{{ scope.row.realName }}</a> -->
                                <a v-else-if="item.field == 'realName'" :href="`/#/candidateDetail/${scope.row.resumeId}`" target="_blank">{{ scope.row.realName }}</a>
                                <span v-else>{{ columnFormatter(item.field, scope.row[item.field]) }}</span>
                            </template>
                        </el-table-column>
                    </template>

                    <!-- 候选人状态列 -->
                    <el-table-column
                        v-if="!shareLogId && type!='offerList'"
                        key="candidateStatus"
                        prop="candidateStatus"
                        align="left"
                        stripe
                        :show-overflow-tooltip="true"
                        :resizable="false"
                        min-width="176"
                        label="候选人状态">
                        <template slot-scope="scope">
                            {{scope.row.candidateStatus}}
                            <template v-if="scope.row.hasUpdateLog && isAdministrator">
                                <el-popover
                                    placement="bottom-end"
                                    width="480"
                                    popper-class="offer-edit-log-popper"
                                    :visible-arrow="false"
                                    trigger="hover"
                                    @show="getEditLogList(scope.row.recommendId)">
                                    <div
                                        class="offer-edit-log-list"
                                        v-loading="offerLogLoading">
                                        <div
                                            class="offer-edit-log-item"
                                            v-for="(log, index) in editOfferLogList"
                                            :key="index">
                                            <p class="offer-info-time">{{log.updateTime | dataFilter}}</p>
                                            <div class="offer-log-title"><span class="offer-log-updator">{{log.updaterName}}</span>编辑Offer</div>
                                            <p class="offer-log-title">编辑前</p>
                                            <div v-html="transformOfferLog(log.originModel)"></div>
                                            <p class="offer-log-title">编辑后</p>
                                            <div v-html="transformOfferLog(log.updateModel)"></div>
                                        </div>
                                    </div>
                                    <i slot="reference" class="el-icon-document"></i>
                                </el-popover>
                            </template>
                        </template>
                    </el-table-column>

                    <!-- 简历来源渠道 -->
                    <el-table-column
                        v-if="isCaculateOpen && !shareLogId"
                        key="candidateSourceChannel"
                        align="left"
                        class="cell-recommend-name"
                        min-width="180"
                        prop="candidateSourceChannel"
                        :show-overflow-tooltip="true"
                        :resizable="false"
                        label="简历来源渠道">
                    </el-table-column>

                    <!-- 业务分类 -->
                    <el-table-column
                        v-if="isCFUser && !shareLogId"
                        key="businessClassificationName"
                        align="left"
                        class="cell-recommend-name"
                        min-width="180"
                        prop="businessClassificationName"
                        :show-overflow-tooltip="true"
                        :resizable="false"
                        label="业务分类">
                        <template slot-scope="scope">
                            {{ scope.row.businessClassificationName || '-' }}
                        </template>
                    </el-table-column>
                    
                </el-table>
                <div class="work-table-footer">
                    <el-pagination :current-page="page" :page-sizes="[15, 20, 50]" :page-size="pageSize" class="el-pagination-workTable" layout="total, sizes, prev, pager, next, slot" :total="totalCount" @size-change="handleSizeChange" @current-change="handleCurrentChange">
                        <span class="pagination-text">
                            <span>前往<el-input v-model="pagerJump" @keyup.enter.native="handlePagerJump"></el-input>页</span>
                            <span @click="handlePagerJump">跳转</span>
                        </span>
                    </el-pagination>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import { performances_detail_new } from '#/js/config/api.json';
    import { offerReport as offerReportUrl } from '#/js/config/javaApi.json';
    import { currency as currencyFilter } from '#/js/filters/number.js';
    import trackListShareTitle from './track-list-share-title.js';
    import offeringListTableTitle from './offering-List-table-title.js';
    import moment from 'moment';
    import myService from '#/js/service/myService.js';
    import {getQueryString } from '#/js/util/tools.js';
    import InquiryLog from '../talent-pool/component/inquiryLog.vue';
    import { downloadFile } from '#/js/util/fileUtil.js'
    import performanceSetService from '#/js/service/performanceSetService.js';
    import TeamService from '#/js/service/teamService.js';
    import TeamMemberSelect from '#/component/common/team-member-select.vue';

    const DegreeMap = {
        "0": "不要求",
        "1": "高中及以下",
        "2": "大专",
        "3": "本科",
        "4": "硕士",
        "5": "MBA",
        "6": "博士"
    };

    export default {
        name: "OfferReport",
        components: {
            InquiryLog,
            TeamMemberSelect,
        },
        data() {
            let _minTime = null,
                _maxTime = new Date().getTime();
            return {
                exportLoading: false,
                // allMembersIds: '', // 所有成员Id
                loading: false,
                isFirstFetch: true,
                clientOptions: [],
                clientVisible: false, //用于控制客户的三角
                clientValue: '',
                offerTableTitle: [
                    {
                        field: 'recommendName',
                        label: '推荐人',
                        class: 'cell-recommend-name',
                        width: '74'
                    },
                    {
                        field: 'customerName',
                        label: '客户名称',
                        class: 'cell-recommend-name',
                        width: '82'
                    },
                    {
                        field: 'department',
                        label: '事业部',
                        class: 'cell-recommend-name',
                        width: '96'
                    },
                    {
                        field: 'title',
                        label: '推荐至职位',
                        class: 'cell-recommend-name',
                        width: '126'
                    },
                    {
                        field: 'jobSource',
                        label: '职位来源',
                        class: 'cell-recommend-name',
                        width: '96'
                    },
                    {
                        field: 'locations',
                        label: '工作地点',
                        class: 'cell-recommend-name',
                        width: '96'
                    },
                    {
                        field: 'realName',
                        label: '候选人姓名',
                        class: 'cell-recommend-name',
                        width: '120'
                    },
                    {
                        field: 'currentCompany',
                        label: '目前公司',
                        class: 'cell-recommend-name',
                        width: '82'
                    },
                    {
                        field: 'offerTime',
                        label: 'offer时间',
                        class: 'cell-recommend-name',
                        width: '104'
                    },
                    {
                        field: 'hireTime',
                        label: '入职时间',
                        class: 'cell-recommend-name',
                        width: '104'
                    },
                    {
                        field: 'serviceCharge',
                        label: '服务费',
                        class: 'cell-service-charge',
                        width: '146'
                    },
                ],
                allOffersTableTitle: [
                    {
                        field: 'recommendName',
                        label: '推荐人',
                        class: 'cell-recommend-name',
                        width: '104'
                    },
                    {
                        field: 'recommendId',
                        label: '推荐ID',
                        class: 'cell-recommend-name',
                        width: '320'
                    },
                    {
                        field: 'customerName',
                        label: '客户名称',
                        class: 'cell-recommend-name',
                        width: '112'
                    },
                    {
                        field: 'customerDomain',
                        label: '所属领域',
                        class: 'cell-recommend-name',
                        width: '102'
                    },
                    {
                        field: 'department',
                        label: '事业部',
                        class: 'cell-recommend-name',
                        width: '106'
                    },
                    {
                        field: 'title',
                        label: '推荐至职位',
                        class: 'cell-recommend-name',
                        width: '126'
                    },
                    {
                        field: 'jobSource',
                        label: '职位来源',
                        class: 'cell-recommend-name',
                        width: '106'
                    },
                    {
                        field: 'locations',
                        label: '工作地点',
                        class: 'cell-recommend-name',
                        width: '106'
                    },
                    {
                        field: 'realName',
                        label: '候选人姓名',
                        class: 'cell-recommend-name',
                        width: '120'
                    },
                    {
                        field: 'currentCompany',
                        label: '目前公司',
                        class: 'cell-recommend-name',
                        width: '132'
                    },
                    {
                        field: 'offerTime',
                        label: 'offer时间',
                        class: 'cell-recommend-name',
                        width: '124'
                    },
                    {
                        field: 'hireTime',
                        label: '入职时间',
                        class: 'cell-recommend-name',
                        width: '124'
                    },{
                        field: 'serviceCharge',
                        label: '服务费',
                        class: 'cell-service-charge',
                        width: '146'
                    },{
                        field: 'performance',
                        label: '业绩',
                        class: 'cell-performance',
                        width: '146'
                    }
                ],
                trackListShareTitle: trackListShareTitle,
                extraTableTitle: [],
                totalCount: 0,
                page: 1,
                pageSize: 15,
                pagerJump: 0,
                recommendStartTime: '',
                recommendEndTime: '',
                recommendOwnerIds: [],
                isRelateOffer: false,
                searchType: false,
                tableData: [],
                dateRange: [],
                isFocusDate: false,//用于控制日期的三角
                expireTimeOption: {
                    onPick: time => {
                        // 如果选择了只选择了一个时间
                        if (!time.maxDate) {
                            let timeRange = 365*24*60*60*1000, // *天
                                _dNow = new Date().getTime();

                            _minTime = time.minDate.getTime() - timeRange; // 最小时间
                            _maxTime = time.minDate.getTime() + timeRange; // 最大时间
                            _maxTime = moment(_maxTime).isSameOrAfter(_dNow) ? _dNow: _maxTime;
                        // 如果选了两个时间，那就清空本次范围判断数据，以备重选
                        } else {
                            _minTime = _maxTime = null;
                        }
                    },
                    disabledDate: time => {
                        // onPick后触发
                        // 该方法会轮询当3个月内的每一个日期，返回false表示该日期禁选
                        if(_minTime && _maxTime){
                            return time.getTime() < _minTime || time.getTime() > _maxTime;
                        }
                        if(!_minTime && _maxTime) {
                            return time.getTime() > _maxTime;
                        }
                    },
                    // disabledDate(date) {
                    //     return date.getTime() > Date.now();
                    // },
                    shortcuts: [{
                        text: '昨天',
                        onClick(picker) {
                            const end = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(1, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '最近7天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(6, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近30天',
                        onClick(picker) {
                            const end = moment().subtract(0, 'days').format('YYYY-MM-DD');
                            const start = moment().subtract(29, 'days').format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本周',
                        onClick(picker) {
                            const start = moment().weekday(0).format('YYYY-MM-DD');
                            const end = moment().weekday(6).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '本月',
                        onClick(picker) {
                            const start = moment().add('month', 0).format('YYYY-MM') + '-01';
                            const end = moment(start).add('month', 1).add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '上月',
                        onClick(picker) {
                            const start = moment().subtract(1, 'month').format('YYYY-MM') + '-01';
                            const end = moment(start).subtract(-1, 'month').add('days', -1).format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '今年',
                        onClick(picker) {
                            const start = moment().year() + '-01-01';
                            const end = moment().format('YYYY-MM-DD');
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                offerLogLoading: false,
                editOfferLogList: [],

                offerTitleMap: {
                    offer: 'Offer数：',
                    relateOffer: '相关Offer数：',
                    offerList: 'Offering List：',
                    clientOffer: '综合协调人客户Offer数：',
                },
                isTeam: 0,

                teamMemberPopoverVisible: false,
                selectedMembersText: '全部成员',
            }
        },
        computed: {
            firmId() {
                return this.$store.state.user.userInfo.firmId;
            },
            isCaculateOpen() {
                return this.$store.state.user.userInfo.isOpenPerformanceCalculation;
            },
            isAdministrator() {
                return this.$store.state.user.userInfo.isAdministrator;
            },
            shareLogId() {
                return this.$route.query.shareLogId;
            },
            shareFrom() {
                let _shareFrom;
                if(sessionStorage.getItem('trackListShareFrom')) {
                    _shareFrom = sessionStorage.getItem('trackListShareFrom');
                } else {
                    let url = window.location.hash;
                    const _idx = url.indexOf('shareFrom');
                    if(_idx >=0 ) {
                        _shareFrom = url.slice(_idx + 10, url.length);
                    }
                    _shareFrom = decodeURI(decodeURI(_shareFrom));
                    sessionStorage.setItem('trackListShareFrom', _shareFrom);
                }
                
                _shareFrom = _shareFrom.replace(/%40/g, '@');
                return _shareFrom.replace(/%3F/g, '?');
            },
            type() {
                return this.$route.query.type;
            },
            isCFUser() {
                return this.$store.state.user.userInfo.isCFUser;
            },
            isInternalControl () {
                return this.$store.state.user.userInfo.roleCodeList?.includes('InternalControl');
            },
            isAgreementJob() {
                return (this.$store.state.user.userInfo.privilegeCodeList || []).includes('AgreementJob');
            },
        },
        filters: {
            numPointFilter(val) {
                return val.replace('.00', '');
            },
            dataFilter(val) {
                return moment(val).format('YYYY-MM-DD HH:mm:SS')
            }
        },
        created() {
            if(this.$route.query.type == 'relateOffer') {
                this.isRelateOffer = true;
                // document.title = '业绩_全部相关Offer_猎必得_有效的O2O猎头招聘平台'
            }
            if(this.type == 'offerList') {
                this.allOffersTableTitle = offeringListTableTitle;
                this.offerTableTitle = offeringListTableTitle;
            }
            // 是综合协调人客户offers数
            if(this.$route.query.type == 'clientOffer') {
                this.searchType = true;
            }

            // 非cf用户不展示所属领域列
            if(!this.isCFUser) {
                this.allOffersTableTitle = this.allOffersTableTitle.filter(item => item.field != 'customerDomain');
                this.offerTableTitle = this.offerTableTitle.filter(item => item.field != 'customerDomain');
            }
        },
        mounted() {
            if(sessionStorage.getItem('offerReportData')) {
                let json = JSON.parse(sessionStorage.getItem('offerReportData'));
                this.recommendStartTime = json.startDate;
                this.recommendEndTime = json.endDate;
                this.recommendOwnerIds = json.userIds || [];
                this.isTeam = json.isTeam;
                this.selectedMembersText = json.selectedMembersText;
            }else if(localStorage.getItem('offerReportData')) {
                let jsonStr = localStorage.getItem('offerReportData');
                sessionStorage.setItem('offerReportData', jsonStr);

                let json = JSON.parse(sessionStorage.getItem('offerReportData'));
                this.recommendStartTime = json.startDate;
                this.recommendEndTime = json.endDate;
                this.recommendOwnerIds = json.userIds || [];
                this.isTeam = json.isTeam;
                this.selectedMembersText = json.selectedMembersText;
            }
            this.dateRange = [moment(this.recommendStartTime).format('YYYY-MM-DD'), moment(this.recommendEndTime).format('YYYY-MM-DD')];
            if(this.searchType){
                this.getGeneralCoordinatorCustomer();
                // this.getTeamMembers();
            }
            this.getData();
            this.$refs.teamSelect.getHistoryTeamData();
        },
        methods: {
            // 获取所有成员
            // getTeamMembers() {
            //     TeamService.getTeamMembers({
            //         teamId: 0,
            //         take: -1,
            //         isShowDimission: true
            //     }).then(res => {
            //         // console.log(res);
            //         // 重新置空，避免重复push
            //         this.allMembersIds = [];
            //         res.list.forEach(el=>{
            //             this.allMembersIds.push(el.userId);
            //         })
            //         // console.log(this.allMembersIds)
            //         // console.log(this.allMembersIds.length)
            //         this.getData();
            //     }).catch(err => {
            //         console.log(err)
            //     })
            // },
            getCode(col) {
                console.log(`col--------------`, col)
            },
            // 获取综合协调人的客户列表
            getGeneralCoordinatorCustomer(){
                performanceSetService.getGeneralCoordinatorCustomer()
                    .then(res =>{
                        // console.log(res)
                        this.clientOptions = res;
                    }).catch(err =>{
                        console.log(err)
                    }).finally(() => {
                    });
            },
            clientSelectFocus(){
                this.clientVisible = true;
            },
            clientSelectBlur(){
                this.clientVisible = false;
            },
            generateData(param) {
                let data;
                if(param && param.isExport) {
                    data = {
                        isRelateOffer: this.isRelateOffer ? 1 : 0,
                        searchType: this.searchType ? 2 : 1,
                    }
                }else {
                    data = {
                        offset: (this.page - 1) * this.pageSize,
                        pageSize: this.pageSize,
                        isRelateOffer: this.isRelateOffer ? 1 : 0,
                        searchType: this.searchType ? 2 : 1
                    }
                }
                if(this.recommendStartTime) data.recommendStartTime = this.recommendStartTime.replace(/\//g, '-');
                if(this.recommendEndTime) data.recommendEndTime = this.recommendEndTime.replace(/\//g, '-');
                if(this.recommendOwnerIds.length > 0) data.recommendOwnerIds = this.recommendOwnerIds;
                return data;
            },
            getData() {
                this.loading = true;
                let data = this.generateData();
                // let _url = this.isRelateOffer ? offerReportUrl.get_relate_list : offerReportUrl.get_list;
                let _url = '';

                switch(this.type) {
                    case 'offer': 
                        _url = offerReportUrl.get_list;
                        break;
                    case 'relateOffer':
                        _url = offerReportUrl.get_relate_list;
                        data = {
                            ...data,
                            isTeam: this.isTeam,
                        };
                        break;
                    case 'offerList':
                        _url = offerReportUrl.offering_list_detail;
                        data = {
                            ...data,
                            achievementDetailType: null,
                            startDate: data.recommendStartTime,
                            endDate: data.recommendEndTime,
                            userIds: data.recommendOwnerIds
                        };
                        break;
                    case 'clientOffer': 
                        // _url = offerReportUrl.get_list;
                        _url = offerReportUrl.get_general_coordinator_list;
                        data = {
                            ...data,
                            // recommendOwnerIds: this.allMembersIds,
                            customerIds: this.clientValue ? [this.clientValue] : []
                        };
                        break;
                    default:
                        break;
                }
                if(this.shareLogId) {
                    data = {
                        offset: (this.page - 1) * this.pageSize,
                        pageSize: this.pageSize,
                        shareLogId: this.shareLogId,
                    };
                    _url = performances_detail_new.tracking_list_share_list;
                }
                _request({
                    method: 'POST',
                    baseURL: 'LbdJavaApi',
                    javaProject: 'performance',
                    url: _url,
                    data: data
                }).then(res =>{
                    // console.log(res)
                    // console.log(res.performanceNames)
                    // console.log(this.isFirstFetch)
                    // console.log(res.performanceNames.length)
                    if(res.performanceNames && res.performanceNames.length > 0) {
                        this.extraTableTitle = res.performanceNames.map(item =>{
                            return {
                                field: item,
                                label: `${item}/分成比例/分成值`,
                                class: '',
                                width: '198'
                            }
                        });
                        console.log(this.extraTableTitle)
                        // this.isFirstFetch = false;
                    }
                    if(['relateOffer', 'offer'].includes(this.type) && (res.data) && this.hasA2aColumn(res.data)) {
                        const _idx = this.extraTableTitle.findIndex(item => item.field == 'a2AOfferItems');
                        if(_idx < 0) {
                            this.extraTableTitle.push({
                                field: 'a2AOfferItems',
                                label: `A2A/分成值`,
                                class: '',
                                width: '188'
                            });
                        }
                    }

                    this.totalCount = res.count;
                    this.tableData = res.data || [];
                    this.loading = false;
                    // this.isFirstFetch = false;
                    // this.isFirstFetch = this.tableData.length > 0 ? false: true;
                    this.tableData.forEach(item => {
                        if(item.teamChains && item.teamChains instanceof Array) {
                            item.teamChains = item.teamChains.join('、');
                        }
                    });

                    if(this.shareLogId) {
                        this.tableData.forEach(item => {
                            let _resumeListItem = {
                                inquiryLogsCount: item.inquiryLogCount,
                                realName: item.realName,
                                resumeId: item.resumeId,
                            };
                            item.resumeListItem = _resumeListItem;
                        });
                    }
                    
                    // 刷新定位到页面顶部
                    // const _scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
                    const _scrollTop = document.querySelector(".offer-report").scrollTop;
                    if(_scrollTop > 200) {
                        // document.documentElement.scrollTop = 0;
                        // document.body.scrollTop = 0;
                        document.querySelector(".offer-report").scrollTop = 0;
                    }
                });
            },

            // 判断是否显示A2a分成列
            hasA2aColumn(arr = []) {
                if(arr.length == 0) return false;
                return arr.some(item => {
                    return item.a2AOfferItems && item.a2AOfferItems?.length > 0;
                });
            },

            handlePagerJump() {
                let currentPager = Number(this.pagerJump),
                currentPageTotal = Math.ceil(this.totalCount/this.pageSize);
                if(currentPager > 0 && currentPager <= currentPageTotal){
                    this.page = currentPager;
                    this.getData();
                }
            },
            handleCurrentChange(val){
                this.page = val;
                this.getData();
            },
            handleSizeChange(val){
                this.page = 1;
                this.pageSize = val;
                this.getData();
            },
            columnFormatter(column, cellValue) {
                if(column == 'offerTime' || column == 'hireTime') {
                    return cellValue ? moment(cellValue).format('YYYY-MM-DD') : ''
                }else if(column == 'serviceCharge' || column == 'performance') {
                    return currencyFilter(cellValue, '', 2);
                }else if(column == 'degree'){
                    return DegreeMap[cellValue];
                }else if(column == 'inquiryLogCount'){
                    return '';
                }else {
                    return cellValue;
                }
            },
            handleExport() {
                this.exportLoading = true;
                // this.loading = true;
                let data = this.generateData({ isExport: true });
                let tempWindow = window.open('', '_self');
                let _url = offerReportUrl.export;
                // let _url = offerReportUrl.general_coordinator_export;

                if(this.type == 'offerList') {
                    _url = offerReportUrl.offering_list_export;
                    data = {
                        ...data,
                        achievementDetailType: null,
                        startDate: data.recommendStartTime,
                        endDate: data.recommendEndTime,
                        userIds: data.recommendOwnerIds,
                        offset: (this.page - 1) * this.pageSize,
                        pageSize: this.pageSize,
                    };
                    
                    Reflect.deleteProperty(data, 'recommendStartTime');
                    Reflect.deleteProperty(data, 'recommendEndTime');
                    Reflect.deleteProperty(data, 'recommendOwnerIds');
                    // offering list采用文件流下载的
                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: _url,
                        responseType: 'blob',
                        data: data
                    }).then(res =>{
                        downloadFile(res);
                        this.exportLoading = false;
                        // this.loading = false;
                    });
                } else if(this.type == 'clientOffer'){
                    _url = offerReportUrl.general_coordinator_export;
                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: _url,
                        data: data
                    }).then(res =>{
                        this.exportLoading = false;
                        // this.loading = false;
                        if(res) {
                            tempWindow.location = res;
                        }
                    });
                } else {
                    _request({
                        method: 'POST',
                        baseURL: 'LbdJavaApi',
                        javaProject: 'performance',
                        url: _url,
                        data: data
                    }).then(res =>{
                        this.exportLoading = false;
                        // this.loading = false;
                        if(res) {
                            tempWindow.location = res;
                        }
                    });
                }
            },
            handleCellMouseEnter(row, column, cell, event, flag) {
                let popperEleArr = document.getElementsByClassName('el-tooltip__popper is-light');
                if(popperEleArr && popperEleArr.length > 0) {
                    Array.prototype.forEach.call(popperEleArr, (popperEle) => {
                        popperEle.innerText = cell.innerText;
                        if(popperEle.innerText.indexOf('\n') > -1) {
                            let html = popperEle.innerText.replace('\n', '<br />');
                            popperEle.innerHTML = popperEle.innerHTML.replace(popperEle.innerText, html);
                        }
                    })
                }else {
                    if(!flag) {
                        setTimeout(() =>{
                            this.handleCellMouseEnter(row, column, cell, event, true);
                        }, 100)
                    }
                }
            },
            handleDate(value) {
                let jsonStr = JSON.parse(sessionStorage.getItem('offerReportData'));
                this.recommendStartTime = (jsonStr.startDate = moment(value[0]).format('YYYY-MM-DD'));
                this.recommendEndTime = (jsonStr.endDate = moment(value[1]).format('YYYY-MM-DD'));
                sessionStorage.setItem('offerReportData', JSON.stringify(jsonStr));
                this.handleCurrentChange(1);
                if(this.isInternalControl) {
                    this.$refs.teamSelect.getHistoryTeamData(true);
                }
            },
            handleFocusDate() {
                this.isFocusDate = true;
            },
            handleBlurDate() {
                this.isFocusDate = false;
            },
            transformOfferLog(log) {
                let list = [],
                    text = "",
                    itemSplitItemText = "",
                    itemSplitItems = [];
                Object.keys(log).forEach(item => {
                    // if(item !== 'performanceSplits' && item !== 'months' && ((item === 'serviceCharge' && log[item] != undefined) || (item !== 'serviceCharge' && log[item]))) {
                    if(['monthPay', 'recruiteTime', 'commissionRate', 'offerLocation', 'serviceCharge', 'remark'].includes(item) && log[item]){
                        switch(item) {
                            case 'monthPay':
                                text = `<p>签订月薪为：${log.months}*${log.monthPay}k</p>`;
                                break;
                            case 'recruiteTime':
                                text = `<p>入职时间为：${moment(log.recruiteTime).format("YYYY-MM-DD")}</p>`;
                                break;
                            case 'commissionRate':
                                text = `<p>佣金比例为：${log.commissionRate}%</p>`;
                                break;
                            case 'offerLocation':
                                text = `<p>工作地点为：${log.offerLocation}</p>`;
                                break;
                            case 'serviceCharge':
                                text = `<p>服务费为：${log.serviceCharge}</p>`;
                                break;
                            case 'remark':
                                text = `<p>备注为：${log.remark}</p>`;
                                break;
                        }
                        list.push(text);
                    }
                });
                
                log.performanceSplits.forEach(item =>{
                    text = `<p>${item.name}为：`;
                    itemSplitItemText = "";
                    itemSplitItems = [];
                    for(let i = 0; i < item.splitItems.length; i++) {
                        itemSplitItemText = `${item.splitItems[i].realName}，`;
                        if(item.type > 0) {
                            itemSplitItemText += `比例为 ${item.splitItems[i].value}%`;
                        } else {
                            itemSplitItemText += `数值为 ${item.splitItems[i].value}`;
                        }
                        itemSplitItems.push(itemSplitItemText);
                    }
                    text += itemSplitItems.join("；");
                    text += '</p>';
                    list.push(text);
                });
                return list.join("");
            },
            getEditLogList(recommendId) {
                // if(!this.editOfferLogList.length) {
                    this.offerLogLoading = true;
                    myService.getOfferEditLog(recommendId).then(res => {
                        this.offerLogLoading = false;
                        this.editOfferLogList = res && res.length ? res : [];
                    }).catch(err => {
                        this.offerLogLoading = false;
                        console.log(err);
                    });
                // }
            },
            handleUpdateItem() {
                console.log(arguments)
            },
            employeeSelect(userIds, text) {
                this.recommendOwnerIds = userIds;
                this.selectedMembersText = text;
                // this.refresh();
                this.teamMemberPopoverVisible = false;
                this.handleCurrentChange(1);
            },
        }
    }
</script>

<style lang="scss">
.offer-report {
    .select-client {
        .el-input__inner {
            &::placeholder {
                color: #606266 !important;
            }
            &::-webkit-input-placeholder {
                color: #606266 !important;
            }
            &::-moz-input-placeholder {
                color: #606266 !important;
            }
            &::-ms-input-placeholder {
                color: #606266 !important;
            }
        }
    }
}
</style>
<style lang="scss" scope>
    .offer-report {
        // background-color: #f9f9f9;
        padding: 20px;
        height: 100%;
        overflow-y: auto;
        &-content {
            padding-bottom: 38px;
            background-color: #fff;
            border-radius: 8px;
            min-height: calc(100vh - 90px);
        }

        &-title {
            display: flex;
            justify-content: space-between;
            line-height: 32px;
            padding: 30px 30px 0;
            margin-bottom: 8px;

            .table-title {
                font-size: 28px;
                color: #666;
                line-height: 32px;

                > span {
                    color: $primary;
                }
            }
            

            // & > span {
            //     color: $primary;
            // }

            // &-btn {
            //     position: absolute;
            //     top: 0;
            //     right: 0;
            //     margin-top: 32px;
            // }
            .export {
                margin-left: 20px;
            }
            // .el-button.is-loading {
            //     position: absolute !important;
            // }
        }

        &-option {
            display: flex;
        }

        .work-table-footer {
            margin: 35px 35px 0 0;
        }

        .cell-share-data {
            width: 136px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .table-header-tip-cell .table-header-tip-icon {
            top: 0;
        }

        /deep/ .el-table th {
            background-color: #f9f9f9;
        }

        /deep/ .el-table--striped .el-table__body tr.el-table__row--striped td {
            background-color: #f9f9f9;
        }

        .el-table {
            thead tr th {
                background-color: #F0F4F7;
                .cell {
                    color: #666;
                    font-weight: bold;
                    padding: 0 20px;
                }
            }
            td .cell {
                padding: 0 20px;
            }
        }
        .el-table th, .el-table td {
            padding: 8px 0;

            a:hover {
                color: #38BC9D !important;
            }
        }

        .sub-title {
            // width: 100%;
            .employees {
                position: relative;
                display: inline-block;
                margin-right: 20px;
                width: 190px;
                height: 32px;
                line-height: 32px;
                padding: 0 12px;
                border: 1px solid #d3d3d3;
                border-radius: 4px;

                .employee-select-text {
                    display: flex;
                    align-items: center;

                    .text {
                        flex: 1;
                        flex-grow: 1;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }

                .employee-select-arrow {
                    margin-right: 0;
                }
            }

            text-align: center;
            .date-wrap {
                display: inline-block;
                position: relative;
                cursor: pointer;
                // width: 190px;
                width: 260px;
                margin: 0 auto 16px;
                &:hover{
                    .el-date-editor--daterange.el-input__inner{
                        border-color:#38BC9D;
                        background: #38BC9D;
                        .el-range-input{
                            background: #38BC9D !important;
                            color: #fff !important;;
                        }
                        .el-range-separator{
                            color: #fff;
                        }
                    }
                }
                .el-range-editor.is-active{
                    background: #38BC9D;
                    .el-range-input{
                        background: #38BC9D;
                        color: #fff;
                    }
                    .el-range-separator{
                        color: #fff;
                    }
                }
                &.triangle-drop-up{
                    &::after{
                        border-bottom: 12px solid #fff;
                    }
                }
                .el-date-editor--daterange.el-input__inner{
                    // width: 190px;
                    width: 260px;
                    // border-radius: 16px;
                }
                .el-date-editor .el-range__icon{
                    // display: none;
                }

                .el-date-editor .el-range__close-icon{
                    display: none;
                }
            }
            .client-select-wrap{
                display: inline-block;
                position: relative;
                margin-left: 20px;
                vertical-align: top;
                .el-select.select-client {
                    &:hover {
                        .el-input__inner{
                            background: #38BC9D;
                            border-color: #38BC9D;
                            color: #fff;
                            &::placeholder {
                                color: #fff !important;
                            }
                            &::-webkit-input-placeholder {
                                color: #fff !important;
                            }
                            &::-moz-input-placeholder {
                                color: #fff !important;
                            }
                            &::-ms-input-placeholder {
                                color: #fff !important;
                            }
                        }
                    }
                    input.el-input__inner {
                        // width: 118px;
                        width: 190px;
                        height: 32px;
                        // border-radius: 16px;
                    }
                    .el-icon-arrow-up:before {
                        display: none;
                    }
                }
            }
        }
        .triangle-drop{
            &:after{
                position: absolute;
                display: block;
                content: '';
                top: 50%;
                transform: translateY(-50%);
                right: 16px;
            }
            &-down{
                &:after{
                    border-top: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-top: 12px solid #fff;
                    }
                }

            }
            &-up{
                &:after{
                    border-bottom: 12px solid #ddd;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                }
                &:hover{
                    &:after{
                        border-bottom: 12px solid #fff;
                    }
                }
            }
        }
    }
    .el-select-dropdown__item.client-select-item {
        // width: 350px;
        width: 190px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .el-table__empty-block {
        width: 100% !important;
    }
</style>
<style lang="scss">
    .layout-transition {
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .performance-data-range.el-date-range-picker {
        .popper__arrow {
            left: 50% !important;
        }
    }
    .offer-edit-log-popper.el-popper {
        padding: 0;
        .offer-edit-log-list {
            max-height: 560px;
            overflow: hidden;
            overflow-y: auto;
            padding: 20px;
            .offer-edit-log-item {
                p {
                    color: #999;
                    line-height: 20px;
                    margin-bottom: 0;
                }
                .offer-info-time {
                    margin-bottom: 4px;
                    color: #666;
                }
                .offer-log-title {
                    color: #333;
                    line-height: 20px;
                    .offer-log-updator {
                        color: $primary;
                        margin-right: 5px;
                    }
                }
                & + .offer-edit-log-item {
                    margin-top: 20px;
                }
            }
        }
    }

    .icon.table-body-icon-big{
        width: 20px;
        height: 20px;
        top: -2px;
        vertical-align: middle;
        position: relative;
    }
</style>