export default [
    {
        field: 'sourceCreateDate',
        label: 'offering时间',
        class: 'cell-recommend-name',
        width: '140'
    },
    {
        field: 'recommendationId',
        label: '推荐ID',
        class: 'cell-recommend-name',
        width: '320'
    },
    {
        field: 'teamChains',
        label: '所属团队',
        class: 'cell-recommend-name',
        width: '142'
    },
    {
        field: 'pm',
        label: 'PM',
        class: 'cell-recommend-name',
        width: '96'
    },
    {
        field: 'recommender',
        label: '推荐人',
        class: 'cell-recommend-name',
        width: '96'
    },
    {
        field: 'customerName',
        label: '客户',
        class: 'cell-recommend-name',
        width: '116'
    },
    {
        field: 'customerDomain',
        label: '所属领域',
        class: 'cell-recommend-name',
        width: '116'
    },
    {
        field: 'jobName',
        label: '岗位',
        class: 'cell-recommend-name',
        width: '142'
    },
    {
        field: 'jobSource',
        label: '职位来源',
        class: 'cell-recommend-name',
        width: '142'
    },
    {
        field: 'candidateName',
        label: '候选人',
        class: 'cell-recommend-name',
        width: '104'
    },
    {
        field: 'company',
        label: '当前公司',
        class: 'cell-recommend-name',
        width: '104'
    },
    // {
    //     field: 'serviceCharge',
    //     label: '预估订单金额',
    //     class: 'cell-recommend-name',
    //     width: '134'
    // },
    // {
    //     field: 'performance',
    //     label: '团队归属业绩',
    //     class: 'cell-recommend-name',
    //     width: '104'
    // },
    {
        field: 'status',
        label: '状态',
        class: 'cell-recommend-name',
        width: '154'
    },
    
];